<template>
  <div class="hello">
    <h1>{{ msg }}</h1>
    <div class="message">
      <p>
        Vítejte, na této stránce si můžete prověřit svou znalost Katechismu katolické církve.
      </p>
      <p>
        Na stránce se pracuje, snad brzy bude bohatší a interaktivní.
      </p>
    </div>
    <div class="questions">
      <AskedQuestion/>
    </div>
  </div>
</template>

<script>
import AskedQuestion from "./AskedQuestion.vue";

export default {
  name: 'WelcomeMessage',
  props: {
    msg: String
  },
  components: {
    AskedQuestion
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

ol {
  text-align: left;
}

li {
  padding: 1rem;
  margin-bottom: 1rem;
}

a {
  color: #2E3192;
}

.message {
  font-style: italic;
}


.questions {
  margin-left: 35%;
  margin-right: 35%;
}

/* styles for viewports narrower than 500px */
@media (max-width: 500px) {
  .questions {
    margin-left: 5px;
    margin-right: 5px;
  }
}

</style>
