<template>
  <div>
    <p>Projděte si některé otázky, kterými se budeme zabývat:</p>
    <button @click="previousItem">Předchozí</button>
    <button @click="nextItem">Následující</button>
    <p class="thequestion">{{ currentItem.name }}</p>
  </div>
  <button @click="showCatechismReference">Zobraz číslo v Katechismu katolické církve</button>
  <p v-if="showReference">{{ currentItem.reference }}</p>
</template>

<script>
export default {
  data() {
    return {
      currentIndex: 0,
      showReference: false,
      items: [
        {
          name: 'Když Bůh navštívil svůj lid a splnil zaslíbení daná Abrahámovi a jeho potomstvu, jaká očekávání překonal? ',
          reference: '422 '
        },
        {name: 'Za vlády jakého císaře byl Ježíš ukřižován? ', reference: '423 '},
        {name: 'Co nás disponuje k tomu, že věříme a vyznáváme, že je Ježíš Mesiáš? ', reference: '424 '},
        {name: 'Co je pro první učedníky nemožné? ', reference: '425 '},
        {name: 'Proč je cílem katecheze uvést do společenství s Ježíšem Kristem? ', reference: '426 '},
        {name: 'Podle čeho poznáme, že katecheta doopravdy učí? ', reference: '427 '},
        {name: 'Co je třeba přijmout, abych mohl získat Krista a být s ním spojen? ', reference: '428 '},
        {name: 'Jaká je touha evangelizovat, která se rodí z láskyplného poznání Krista? ', reference: '429 '},
        {name: 'Co znamená v hebrejštině jméno "Ježíš"? ', reference: '430 '},
        {
          name: 'Bůh se nespokojil s vysvobození Izraele z Egypta. O jaké, ještě důležitější vysvobození mu jde? ',
          reference: '431 '
        },
        {name: 'S jakými lidmi se Bůh spojil vtělením? ', reference: '432 '},
        {name: 'Proč je Ježíšovo jméno tak jedinečné a důležité? ', reference: '432 '},
        {
          name: 'Jak často vzýval jméno Boha Spasitele velekněz při smírných obřadech za hříchy Izraele? ',
          reference: '433 '
        },
        {name: 'Jaký vztah mají k Ježíšově jménu zlí duchové? ', reference: '434 '},
        {name: 'Jak je možné, že Ježíšovi učedníci v jeho jménu konají zázraky? ', reference: '434 '},
        {name: 'Uveďte tři příklady přítomnosti Ježíšova jména v křesťanské modlitbě. ', reference: '435 '},
        {name: 'Čím měl být pomazán podle starozákonních předpovědí Mesiáš? ', reference: '436 '},
        {name: 'Jaké tři typy osob byly v Izraeli pomazávány? ', reference: '436 '},
        {name: 'Kdy je Ježíš nazván poprvé Kristus (tedy Pomazaný, Mesiáš)? ', reference: '437 '},
        {name: 'Jaké role v Ježíšově pomazání mají osoby Nejsvětější Trojice? ', reference: '438 '},
        {
          name: 'Proč část Ježíšových současníků, i když očekávali Mesiáše, tak neuznali, že Ježíš má základní rysy mesiášského "Davidova syna"? ',
          reference: '439 '
        },
        {name: 'Jak Ježíš chápal svou mesiášskou důstojnost? Uveďte dva body. ', reference: '440 '},
        {
          name: 'O jaký typ synovství se jedná, když Starý Zákon mluví o andělech, vyvoleném národu, synech Izraele či jejich králích? ',
          reference: '441 '
        },
        {name: 'V čem je synovství v Petrově vyznání "Ty jsi Mesiáš, Syn živého Boha" výjimečné? ', reference: '442 '},
        {name: 'Je Ježíšův Otec stejným způsobem i naším Otcem? ', reference: '443 '},
        {name: 'Při jakých dvou příležitostech označuje Otec Ježíše jako svého milovaného Syna? ', reference: '444 '},
        {name: 'Čím podle se podle listu Římanům projevil Ježíš jako mocný Boží Syn? ', reference: '445 '},
        {name: 'Jakou roli hraje v řeckých překladech Statého Zákona slovo Kyrios (Pán)? ', reference: '446 '},
        {name: 'Jakým novým způsobem používá slovo Kyrios (Pán) Nový Zákon? ', reference: '446 '},
        {
          name: 'Během svého veřejného života projevoval Ježíš svou božskou svrchovanost nad přírodou, nad nemocemi, nad zlými duchy a nad smrtí. Nad čím ještě? ',
          reference: '447 '
        },

      ]
    };
  },
  computed: {
    currentItem() {
      return this.items[this.currentIndex];
    }
  },
  methods: {
    previousItem() {
      if (this.currentIndex > 0) {
        this.currentIndex--;
        this.showReference = false;
      }
    },
    nextItem() {
      if (this.currentIndex < this.items.length - 1) {
        this.currentIndex++;
        this.showReference = false;
      }
    },
    showCatechismReference() {
      this.showReference = !this.showReference;
    }
  }
};
</script>
<style>
.thequestion {
  font-size: 18px;
  border: 1px solid black;
  margin: 10px;
  padding: 10px;
}
</style>